import React from "react";
import { graphql } from 'gatsby';

import Layout from '../../components/layout/index';
import PostGrid from '../../components/post/grid';
import SectionsRenderer from '../../components/sections/renderer';
import Seo from '../../components/seo';
import { pathTo } from '../../routes';
import styles from './index.module.scss';

const Posts = ({
  data: {
    page: {
      title='Please add Title',
      slug,
      sections=[],
      metaTitle='',
      metaDescription,
      metaKeywords=[],
      sharingTitle='',
      sharingDescription,
      sharingImage
    },
    posts=[]
  }
}) => {
  const postItems = posts.edges.map(edge => edge.node) || [];

  return (
    <>
      <Seo
        title={title}
        metaTitle={metaTitle}
        metaDescription={metaDescription && metaDescription.metaDescription}
        sharingTitle={sharingTitle}
        sharingDescription={sharingDescription && sharingDescription.sharingDescription}
        sharingImage={sharingImage && sharingImage.file && sharingImage.file.url}
        currentUrl={pathTo({ __typename: 'ContentfulPage', slug: slug })}
        keywords={metaKeywords}
      />
      <Layout>
        <h1 className={styles.title}>{title}</h1>
        <div className={styles.sections}>
          <SectionsRenderer sections={sections}/>
        </div>
        <div className={styles.posts}>
          <PostGrid title='Aktuelle Beiträge' posts={postItems}/>
        </div>
      </Layout>
    </>
  )
}

export default Posts;

export const query = graphql`
  query PostsQuery($id: String!, $today: Date) {
    page: contentfulPage(id: { eq: $id}) {
      ...PageFields
    }

    posts: allContentfulPost(limit: 1000,sort: {order: DESC, fields: publishedAt}, filter: { publishedAt: { lte: $today }}) {
      edges {
        node {
          ...PostGridItem
        }
      }
    }
  }
`;
